@charset "utf-8";

// ---------------------------------------------------------------------------
//
// General
//
// ---------------------------------------------------------------------------

	html{
		overflow: auto;
	}

	body{
		overflow: hidden;
		min-width: $main_width + px;
		font-family: $base_font;
		color : $text_color;
		font-size: $base_font_size + px;
		line-height: $base_line_height;
	}

	.tb { display: none; }
	.sp { display: none; }
	.pc { display: inline-block; }


.fw {
// ---------------------------------------------------------------------------
//
// tag
//
// ---------------------------------------------------------------------------
	* {
		box-sizing: border-box;
		-webkit-text-size-adjust: 100%;
	}
	a {
		cursor: pointer;
		color: $main_color;
		text-decoration: none;
		-webkit-tap-highlight-color:rgba(0,0,0,0);
		&:hover {
			text-decoration: underline;
		}
	}
	img {
		max-width: 100%;
		height:auto;
		vertical-align: middle;
	}
	section {
		@include clearfix;
		&.section {
			margin-top: $section_margin_top + px;
		}
	}
	sup {
		vertical-align: super;
		line-height: 1;
	}
	sub {
		vertical-align: sub;
		line-height: 1;
	}

	strong { font-weight: bold; }

	button {
		cursor: pointer;
	}
	button,input {
		font-family: $base_font;
	}


// ---------------------------------------------------------------------------
//
// Layout
//
// ---------------------------------------------------------------------------

	// ----------------------------------------------------------
	// Container
	// ----------------------------------------------------------
	#container {

		// contents
		// -----------------------------------------------------
		#contents {
			float: left;
			width: $contents_width + px;
			&.max {
				float: inherit;
				width: 100%;
			}
		}

		// side
		// -----------------------------------------------------
		#side {
			width: $side_width + px;
			float: right;
		}

	}


	// ----------------------------------------------------------
	// inner
	// ----------------------------------------------------------
	.inner {
		display: block;
		max-width: $main_width + px;
		margin: 0 auto;
		@include clearfix;
	}


// ----------------------------------------------------------
// clearfix
// ----------------------------------------------------------
	.clearfix:after {
		content: "";
		display: block;
		clear: both;
		height: 0;
		visibility: hidden;
		overflow: hidden;
		font-size: 0.1em;
		line-height: 0;
	}

	.clearfix {
		min-height: 1px;
	}

	* html .clearfix {
		height: 1px;
		/*\*/
		/*/
		height: auto;
		overflow: hidden;
		/**/
	}



}//.fw
