@charset "utf-8";


// ---------------------------------------------------------------------------------------
//
// Tablet
//
// ---------------------------------------------------------------------------------------

@media only screen and (max-width: $tb_width + px) {//tb
// ----------------------------------------------------------------------
//
// General
//
// ----------------------------------------------------------------------

	body{
		min-width: 100%;
	}

	.pc { display: none; }
	.sp { display: none; }
	.tb { display: inline-block; }

	input[type="text"],input[type="tel"],input[type="email"], textarea, input[type="submit"]{
		-webkit-appearance: none;
		border-radius: 0;
	}

.fw {
// ----------------------------------------------------------------------
//
// tag
//
// ----------------------------------------------------------------------

	a {
		&:hover {
			text-decoration: none;
		}
	}


// ----------------------------------------------------------------------
//
// Layout
//
// ----------------------------------------------------------------------


	// ----------------------------------------------------------
	// Container
	// ----------------------------------------------------------
	#container {

		// contents
		// -----------------------------------------------------
		#contents {

			float: inherit;
			width: 100%;
			&.max {
				float: inherit;
				width: 100%;
			}
		}

		// side
		// -----------------------------------------------------
		#side {
			width: initial;
			float: inherit;
		}

	}

	// ----------------------------------------------------------
	// inner
	// ----------------------------------------------------------
	.inner {
		width: 100%;
		margin: 0;
		padding: 0 10px;
	}

}//.fw

}//tb


// ---------------------------------------------------------------------------------------
//
// Smart Phone
//
// ---------------------------------------------------------------------------------------

@media only screen and (max-width: $sp_width + px) {//sp
// ----------------------------------------------------------------------
//
// General
//
// ----------------------------------------------------------------------

	body{
		min-width: 320px;
	}

	.pc { display: none; }
	.tb { display: none; }
	.sp { display: inline-block; }

.fw {
// ----------------------------------------------------------------------
//
// tag
//
// ----------------------------------------------------------------------

	a {
		&:hover {
			text-decoration: none;
		}
	}


// ----------------------------------------------------------------------
//
// Layout
//
// ----------------------------------------------------------------------


	// ----------------------------------------------------------
	// Container
	// ----------------------------------------------------------
	#container {

		.inner {
			padding: 0 10px;
		}

		// contents
		// -----------------------------------------------------
		#contents {

			float: inherit;
			width: 100%;
			&.max {
				float: inherit;
				width: 100%;
			}
		}

	}

}//.fw

}//sp
