@charset "utf-8";

// @mixin fontSize($size:13) {
// 	font-size: $size + px;
// 	font-size: ( $size * 0.0625 ) + rem;
// }
@mixin fontSize( $pc:$base_font_size, $tb:1, $sp:1 ) {
	font-size: $pc + px;
	@if $tb == 1 {
		$tb: $pc;
	}
	@if $sp == 1 {
		$sp: $tb;
	}
	@media only screen and (max-width: $tb_width + px) {//tb
		font-size: $tb + px;
	}
	@media only screen and (max-width: $sp_width + px) {//sp
		font-size: $sp + px;
	}
}

@mixin transition($sec:0.1, $ease:linear, $delay:0) {
	transition: all $sec+s $ease $delay+s;
	-webkit-transition: all $sec+s $ease $delay+s;
	-ms-transition: all $sec+s $ease $delay+s;
}
@mixin transSlow {
	transition: all 0.4s linear 0s;
	-webkit-transition: all 0.4s linear 0s;
	-ms-transition: all 0.4s linear 0s;
}
@mixin transition2 {
	transition: all 0.15s cubic-bezier(0.550,0.055,0.675,0.190) 0s;
	-webkit-transition: all 0.15s cubic-bezier(0.550,0.055,0.675,0.190) 0s;
	-ms-transition: all 0.15s cubic-bezier(0.550,0.055,0.675,0.190) 0s;
}
@mixin textshadow($x:1, $y:1, $blur:1, $color:rgba(0, 0, 0, 0.1)) {
	text-shadow: $x+px $y+px $blur+px $color;
	-webkit-text-shadow: $x+px $y+px $blur+px $color;
	-ms-text-shadow: $x+px $y+px $blur+px $color;
}
@mixin boxshadow($x:1, $y:1, $blur:1, $spread:0, $color:rgba(0, 0, 0, 0.1)) {
	box-shadow: $x+px $y+px $blur+px $spread+px $color;
	-webkit-box-shadow: $x+px $y+px $blur+px $spread+px $color;
	-ms-box-shadow: $x+px $y+px $blur+px $spread+px $color;
}
@mixin none {
	background: none;
	border: none;
	border-radius: 0;
	padding: 0;
	margin: 0;
	box-shadow: none;
	color: $text_color;
	text-align: left;
	font-size: $base_font_size;
	line-height: $base_line_height;
}

@mixin clearfix {
 &:after {
 content: ".";
 display: block;
 height: 0;
 clear: both;
 visibility: hidden;
 }
}


@mixin percentWidth($myWidth, $maxWidth){
  width: percentage($myWidth / $maxWidth);
}



// ---------------------------------------------------------------------------
//
// flexbox
//
// ---------------------------------------------------------------------------
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}


// ---------------------------------------------------------------------------
//
// arrow
//
// ---------------------------------------------------------------------------
@mixin arrow($color:#000000, $size:6, $width:1,$layout:left,$direction:right) {
	&:before,&:after {
	}
	@if $layout == left {
		&:before {
			content: "";
			margin-right: 8px;
			display: inline-block;
			border-top: solid $width+px $color;
			border-right: solid $width+px $color;
			vertical-align: middle;
			width: $size+px;
			height: $size+px;
		}
	} @else if $layout == right {
		&:after {
			content: "";
			margin-left: 8px;
			display: inline-block;
			border-top: solid $width+px $color;
			border-right: solid $width+px $color;
			vertical-align: middle;
			width: $size+px;
			height: $size+px;
		}
	}
	@if $direction == top {
		&:before,&:after { transform: rotate(-45deg); -webkit-transform: rotate(-45deg); -ms-transform: rotate(-45deg); margin-top: 1px; }
	} @else if $direction == right {
		&:before,&:after { transform: rotate(45deg); -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); margin-top: -1px; }
	} @else if $direction == bottom {
		&:before,&:after { transform: rotate(135deg); -webkit-transform: rotate(135deg); -ms-transform: rotate(135deg); margin-top: -5px; }
	} @else if $direction == left {
		&:before,&:after { transform: rotate(-135deg); -webkit-transform: rotate(-135deg); -ms-transform: rotate(-135deg); }
	}
}

@mixin arrowAbs($color:#000000, $size:6, $width:1,$layout:left,$position:0,$direction:right) {
	position: relative;
	&:before {
		content: "";
		display: block;
		border-top: solid $width+px $color;
		border-right: solid $width+px $color;
		vertical-align: middle;
		width: $size+px;
		height: $size+px;
		position: absolute;
		top: 50%;
		margin-top: -($size*1.414)/2 + px;
	}
	@if $layout == left {
		&:before {
			left: $position+px;
		}
	} @else if $layout == right {
		&:before {
			right: $position+px;
		}
	}
	@if $direction == top {
		&:before { transform: rotate(-45deg); -webkit-transform: rotate(-45deg);  -ms-transform: rotate(-45deg); }
	} @else if $direction == right {
		&:before { transform: rotate(45deg); -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); }
	} @else if $direction == bottom {
		&:before { transform: rotate(135deg); -webkit-transform: rotate(135deg); -ms-transform: rotate(135deg); }
	} @else if $direction == left {
		&:before { transform: rotate(-135deg); -webkit-transform: rotate(-135deg); -ms-transform: rotate(-135deg); }
	}
}

// ---------------------------------------------------------------------------
//
// triangle
//
// ---------------------------------------------------------------------------
@mixin triangle($color:#000000, $width:20, $height:20, $direction:bottom) {
	$width : $width/2;
	position: relative;
	&:before {
		content: "";
		display: block;
		position: absolute;
		width: 0;
		height: 0;
	}
	@if $direction == top {
		&:before {
			margin-left: -($width)+px;
			left: 50%;
			top: -$height+px;
			border-right: $width+px solid transparent;
		    border-bottom: $height+px solid $color;
			border-left: $width+px solid transparent;
		}
	} @else if $direction == right {
		&:before {
			margin-top: -($width)+px;
			right: -$height+px;
			top: 50%;
			border-top: $width+px solid transparent;
			border-bottom: $width+px solid transparent;
		    border-left: $height+px solid $color;
		}
	} @else if $direction == bottom {
		&:before {
			margin-left: -($width)+px;
			left: 50%;
			bottom: -$height+px;
			border-top: $height+px solid $color;
			border-right: $width+px solid transparent;
			border-left: $width+px solid transparent;
		}
	} @else if $direction == left {
		&:before {
			margin-top: -($width)+px;
			left: -$height+px;
			top: 50%;
			border-top: $width+px solid transparent;
			border-right: $height+px solid $color;
			border-bottom: $width+px solid transparent;
		}
	}
}

// ---------------------------------------------------------------------------
//
// triangle line
//
// ---------------------------------------------------------------------------
@mixin triangleBdr($color:#000000, $bgColor:#FFFFFF, $border:1, $width:20, $height:20, $direction:bottom) {
	$width : $width/2;
	position: relative;
	&:before,&:after {
		content: "";
		display: block;
		position: absolute;
		width: 0;
		height: 0;
	}
	@if $direction == top {
		&:before {
			margin-left: -($width)+px;
			left: 50%;
			top: -$height+px;
			border-right: $width+px solid transparent;
		    border-bottom: $height+px solid $color;
			border-left: $width+px solid transparent;
		}
		&:after {
			margin-left: -($width)+px;
			left: 50%;
			top: -($height - ( $border*($height/$width) )) +px;
			border-right: $width+px solid transparent;
		    border-bottom: $height+px solid $bgColor;
			border-left: $width+px solid transparent;
		}
	} @else if $direction == right {
		&:before {
			margin-top: -($width)+px;
			right: -$height+px;
			top: 50%;
			border-top: $width+px solid transparent;
			border-bottom: $width+px solid transparent;
		    border-left: $height+px solid $color;
		}
		&:after {
			margin-top: -($width)+px;
			right: -($height - ( $border*($height/$width) )) +px;
			top: 50%;
			border-top: $width+px solid transparent;
			border-bottom: $width+px solid transparent;
		    border-left: $height+px solid $bgColor;
		}
	} @else if $direction == bottom {
		&:before {
			margin-left: -($width)+px;
			left: 50%;
			bottom: -$height+px;
			border-top: $height+px solid $color;
			border-right: $width+px solid transparent;
			border-left: $width+px solid transparent;
		}
		&:after {
			margin-left: -($width)+px;
			left: 50%;
			bottom: -($height - ( $border*($height/$width) )) +px;
			border-top: $height+px solid $bgColor;
			border-right: $width+px solid transparent;
			border-left: $width+px solid transparent;
		}
	} @else if $direction == left {
		&:before {
			margin-top: -($width)+px;
			left: -$height+px;
			top: 50%;
			border-top: $width+px solid transparent;
			border-right: $height+px solid $color;
			border-bottom: $width+px solid transparent;
		}
		&:after {
			margin-top: -($width)+px;
			left: -($height - ( $border*($height/$width) )) +px;
			top: 50%;
			border-top: $width+px solid transparent;
			border-right: $height+px solid $bgColor;
			border-bottom: $width+px solid transparent;
		}
	}
}

// ---------------------------------------------------------------------------
//
// diagonal Bg
//
// ---------------------------------------------------------------------------
@mixin diagonalBg($bg:#FFFFFF, $color:rgba(0,0,0,0.2), $width:10, $angle:-45deg) {
	background-size: $width + px $width + px;
	background-color: $bg;
	background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}
