
// Font Family
//------------------------------------------------------------------------------

	$base_font: "Benton Sans Reg",'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',"游ゴシック体", "Yu Gothic",'メイリオ',Meiryo,sans-serif,'ＭＳ Ｐゴシック';
	$bold_font: "Benton Sans Bold",'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',"游ゴシック体", "Yu Gothic",'メイリオ',Meiryo,sans-serif,'ＭＳ Ｐゴシック';
	$light_font: "Benton Sans Light",'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',"游ゴシック体", "Yu Gothic",'メイリオ',Meiryo,sans-serif,'ＭＳ Ｐゴシック';

	$sub_font: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','ＭＳ Ｐゴシック',sans-serif;

// Font Size
//------------------------------------------------------------------------------
	$base_font_size: 14;


// Line height
//------------------------------------------------------------------------------
	$base_line_height: 1.71;


// Width
//------------------------------------------------------------------------------

	//全体横幅
	$main_width: 1152;

	//サイドコンテンツ横幅
	$side_width: 220;

	//メインコンテンツ横幅
	$contents_width: 744;


// Break Point
//------------------------------------------------------------------------------

	$pc_width: 912;
	$tb_width: 767;
	$sp_width: 640;


// Header Height
//------------------------------------------------------------------------------

	$header_height: 74;
	$sp_header_height: 50;


// GridStytem
//------------------------------------------------------------------------------

	$grid: 12;//px


// Column Margin
//------------------------------------------------------------------------------

	//カラム間margin
	$col_margin: 20;//px

	//カラム Margin Bottom
	$col_margin_bottom: 0;//px


// section | section.section { margin-top: $section_margin_top + px; } | /common/fw/layout/_layout.scss
//------------------------------------------------------------------------------
	$section_margin_top: 60;


// Color
//------------------------------------------------------------------------------
	//Main color
	$main_color: #000000;
	$sub_color: #d8d6da;

	//text color
	$text_color: #666666;

	//Link color
	$link_color: $text_color;

	//Other
	$black100: #000;
	$black90: #333;
	$black80: #504f4f;
	$black70: #666;
	$black60: #7d7d7d;
	$black50: #999;
	$black40: #b2b1b1;
	$black30: #CCC;
	$black20: #DDD;
	$black10: #e3e3e3;
	$black0: #f7f7f7;

	$red:#ea0a0a;
	$blue:#2D72A7;
	$aqua:#50a4d4;
	$yellow:#ead805;
	$green:#9abc50;

	$white:#ffffff;

	$about_color: #d728a7;
	$peple_color: #b828d7;
	$services_color: #00aeee;
	$works_color: #6054ff;
	$contact_color: #2376ff;

	$gray_bg:#eeeeee;


// Form Height
//------------------------------------------------------------------------------

	$input_height: 30;//px
	$input_color: $main_color;

	// radio checkbox 枠
	$inputIcon_size: 20;//px
	// radio アイコン
	$inputIconRadio_size: 10;//px
	// checkbox アイコン
	$inputIconCheck_size: 16;//px
