@charset "utf-8";

.fw {

// ---------------------------------------------------------------------------
//
// Tablet
//
// ---------------------------------------------------------------------------


@media only screen and (max-width: $tb_width + px) {//tb

	// ----------------------------------------------------------
	// Table
	// ----------------------------------------------------------
		table {
			&.cellBreak {
				margin-top: 1px;
				th {
					background: $black0;
				}
				th,td {
					width: 100% !important;
					display: block;
					margin-top: -1px;
				}
			}
		}
		.tableResponsive {
			overflow-x: auto;
			table {
				width: $contents_width + px;
			}
		}


	// ----------------------------------------------------------
	// Column
	// ----------------------------------------------------------

		// Default
		// -----------------------------------------------------
		.col {

			// >.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.colFifth {
			// 	width:$col_6;
			// }
			// >.col10 { width:$col_10; }
			// >.col11 { width:$col_11; }
			// >.col12 { width:$col_12; }

			>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
				&.tbCol1 { width:$col_1; }
				&.tbCol2 { width:$col_2; }
				&.tbCol3 { width:$col_3; }
				&.tbCol4 { width:$col_4; }
				&.tbCol5 { width:$col_5; }
				&.tbCol6 { width:$col_6; }
				&.tbCol7 { width:$col_7; }
				&.tbCol8 { width:$col_8; }
				&.tbCol9 { width:$col_9; }
				&.tbCol10 { width:$col_10; }
				&.tbCol11 { width:$col_11; }
				&.tbCol12 { width:$col_12; }
				&.tbColFifth { width:$col_per5; }// 1/5
			}

			&.notBreak {
				>.col1 { width:$col_1 !important; }
				>.col2 { width:$col_2 !important; }
				>.col3 { width:$col_3 !important; }
				>.col4 { width:$col_4 !important; }
				>.col5 { width:$col_5 !important; }
				>.col6 { width:$col_6 !important; }
				>.col7 { width:$col_7 !important; }
				>.col8 { width:$col_8 !important; }
				>.col9 { width:$col_9 !important; }
				>.col10 { width:$col_10 !important; }
				>.col11 { width:$col_11 !important; }
				>.col12 { width:$col_12 !important; }
				>.colFifth { width:$col_per5 !important; }// 1/5
			}


			// Margin Setting
			// -----------------------------------------------------
			//colTbMg
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colTbMg#{$num * 1} {
					margin: 0 (-1)*($num/2)+px $col_margin_bottom + px (-1)*($num/2)+px;
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						padding-left: ($num/2)+px;
						padding-right: ($num/2)+px;
					}
				}
			}
			//colTbMb
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colTbMb#{$num * 1} {
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						margin-bottom: $num + px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			&.fit {
				margin: 0 -20px $col_margin_bottom + px -20px;
				>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
					//margin-bottom: 10px;
				}
			}

		}

	// ----------------------------------------------------------
	// Table Layout
	// ----------------------------------------------------------

		// Defalt
		// -----------------------------------------------------
		.table {

			>.tbCellBreak {
				display: block;
				width: 100%;
			}

			>.tbCell1, >.tableRow >.tbCell1 { width:$col_1; }
			>.tbCell2, >.tableRow >.tbCell2 { width:$col_2; }
			>.tbCell3, >.tableRow >.tbCell3 { width:$col_3; }
			>.tbCell4, >.tableRow >.tbCell4 { width:$col_4; }
			>.tbCell5, >.tableRow >.tbCell5 { width:$col_5; }
			>.tbCell6, >.tableRow >.tbCell6 { width:$col_6; }
			>.tbCell7, >.tableRow >.tbCell7 { width:$col_7; }
			>.tbCell8, >.tableRow >.tbCell8 { width:$col_8; }
			>.tbCell9, >.tableRow >.tbCell9 { width:$col_9; }
			>.tbCell10, >.tableRow >.tbCell10 { width:$col_10; }
			>.tbCell11, >.tableRow >.tbCell11 { width:$col_11; }
			>.tbCell12, >.tableRow >.tbCell12 { width:$col_12; }
			>.tbCellFifth, >.tableRow >.tbCellFifth { width:$col_per5; }// 1/5

		}
}//tb




// ---------------------------------------------------------------------------
//
// Smart Phone
//
// ---------------------------------------------------------------------------

@media only screen and (max-width: $sp_width + px) {//sp

	// ----------------------------------------------------------
	// Table
	// ----------------------------------------------------------
		.tableResponsive {
			overflow-x: auto;
			table {
				width: $contents_width + px;
			}
		}

	// ----------------------------------------------------------
	// Column
	// ----------------------------------------------------------

		// Defalt
		// -----------------------------------------------------
		.col {

			>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
				// width:$col_12;
				&.spCol1 { width:$col_1; }
				&.spCol2 { width:$col_2; }
				&.spCol3 { width:$col_3; }
				&.spCol4 { width:$col_4; }
				&.spCol5 { width:$col_5; }
				&.spCol6 { width:$col_6; }
				&.spCol7 { width:$col_7; }
				&.spCol8 { width:$col_8; }
				&.spCol9 { width:$col_9; }
				&.spCol10 { width:$col_10; }
				&.spCol11 { width:$col_11; }
				&.spCol12 { width:$col_12; }
				&.spColFifth { width:$col_per5; }// 1/5
			}

			&.notBreak {
				>.col1 { width:$col_1 !important; }
				>.col2 { width:$col_2 !important; }
				>.col3 { width:$col_3 !important; }
				>.col4 { width:$col_4 !important; }
				>.col5 { width:$col_5 !important; }
				>.col6 { width:$col_6 !important; }
				>.col7 { width:$col_7 !important; }
				>.col8 { width:$col_8 !important; }
				>.col9 { width:$col_9 !important; }
				>.col10 { width:$col_10 !important; }
				>.col11 { width:$col_11 !important; }
				>.col12 { width:$col_12 !important; }
				>.colFifth { width:$col_per5 !important; }// 1/5
			}

			// Margin Setting
			// -----------------------------------------------------
			//colSpMg
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colSpMg#{$num * 1} {
					margin: 0 (-1)*($num/2)+px $col_margin_bottom + px (-1)*($num/2)+px;
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						padding-left: ($num/2)+px;
						padding-right: ($num/2)+px;
					}
				}
			}
			//colSpMb
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colSpMb#{$num * 1} {
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						margin-bottom: $num + px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			// SP 2Column
			// -----------------------------------------------------
				&.spCol6 {
					.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.colFifth {
						width:$col_6 !important;
					}
				}

		}

		// Order
		// -----------------------------------------------------
		.flexbox {

			@include flexbox();
			@include flex-direction(column);

			@for $i from 1 through 99 {
				.order#{$i} {
					@include order($i);
				}
			}
			>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
				float: none;
			}
		}

	// ----------------------------------------------------------
	// Table Layout
	// ----------------------------------------------------------

		// Defalt
		// -----------------------------------------------------
		.table {

			>.spCellBreak {
				display: block;
				width: 100%;
			}

			>.spCell1, >.tableRow >.spCell1 { width:$col_1; }
			>.spCell2, >.tableRow >.spCell2 { width:$col_2; }
			>.spCell3, >.tableRow >.spCell3 { width:$col_3; }
			>.spCell4, >.tableRow >.spCell4 { width:$col_4; }
			>.spCell5, >.tableRow >.spCell5 { width:$col_5; }
			>.spCell6, >.tableRow >.spCell6 { width:$col_6; }
			>.spCell7, >.tableRow >.spCell7 { width:$col_7; }
			>.spCell8, >.tableRow >.spCell8 { width:$col_8; }
			>.spCell9, >.tableRow >.spCell9 { width:$col_9; }
			>.spCell10, >.tableRow >.spCell10 { width:$col_10; }
			>.spCell11, >.tableRow >.spCell11 { width:$col_11; }
			>.spCell12, >.tableRow >.spCell12 { width:$col_12; }
			>.spCellFifth, >.tableRow >.spCellFifth { width:$col_per5; }// 1/5

		}

}//sp

}//.fw
