@charset "utf-8";


	$col_width : 100/12/100;
	$col_1  : percentage($col_width);
	$col_2  : percentage($col_width*2);
	$col_3  : percentage($col_width*3);
	$col_4  : percentage($col_width*4);
	$col_5  : percentage($col_width*5);
	$col_6  : percentage($col_width*6);
	$col_7  : percentage($col_width*7);
	$col_8  : percentage($col_width*8);
	$col_9  : percentage($col_width*9);
	$col_10 : percentage($col_width*10);
	$col_11 : percentage($col_width*11);
	$col_12 : percentage($col_width*12);
	$col_per5 : percentage(100/5/100);


.fw {

// ---------------------------------------------------------------------------
//
// Base
//
// ---------------------------------------------------------------------------

	// ----------------------------------------------------------
	// Margin / Padding
	// ----------------------------------------------------------

		@for $i from 0 through 20 {
			.mt#{$i * 5}  { margin-top: 5px * $i !important; }
		}

		@for $i from 0 through 20 {
			.mr#{$i * 5}  { margin-right: 5px * $i !important; }
		}

		@for $i from 0 through 20 {
			.mb#{$i * 5}  { margin-bottom: 5px * $i !important; }
		}

		@for $i from 0 through 20 {
			.ml#{$i * 5}  { margin-left: 5px * $i !important; }
		}

		@for $i from 0 through 20 {
			.pt#{$i * 5}  { padding-top: 5px * $i !important; }
		}
		@for $i from 0 through 20 {
			.pr#{$i * 5}  { padding-right: 5px * $i !important; }
		}
		@for $i from 0 through 20 {
			.pb#{$i * 5}  { padding-bottom: 5px * $i !important; }
		}
		@for $i from 0 through 20 {
			.pl#{$i * 5}  { padding-left: 5px * $i !important; }
		}


		@for $i from 0 through 20 {
			.mg#{$i * 5}  { margin: 5px * $i !important; }
		}

		@for $i from 0 through 20 {
			.pd#{$i * 5}  { padding: 5px * $i !important; }
		}


	// ----------------------------------------------------------
	// Align
	// ----------------------------------------------------------
		.txtL { text-align: left !important; }
		.txtC { text-align: center !important; }
		.txtR { text-align: right !important; }


	// ----------------------------------------------------------
	// Text Color
	// ----------------------------------------------------------
		.txtMain { color: $main_color !important; }
		.txtRed { color:$red !important;}
		.txtBlue { color:$blue !important;}
		.txtWhite { color:#FFF !important;}
		.txtYellow { color:$yellow !important;}
		.txtGreen { color:$green !important;}

	// ----------------------------------------------------------
	// Letter Spacing
	// ----------------------------------------------------------
		.spaceS { letter-spacing: -0.02em !important; }
		.spaceM { letter-spacing: 0.03em !important;}
		.spaceL { letter-spacing: 0.08em !important;}

	// ----------------------------------------------------------
	// Font Size
	// ----------------------------------------------------------
		.fontL { font-size: $base_font_size + 4 + px ; }
		.fontM { font-size: $base_font_size + 2 + px ; }
		.fontS { font-size: $base_font_size - 2 + px ; }

	// ----------------------------------------------------------
	// Font Weight
	// ----------------------------------------------------------
		.fontB {font-weight: bold !important;}


	// ----------------------------------------------------------
	// border
	// ----------------------------------------------------------
		.solidT { border-top: 1px solid $main_color; }
		.solidL { border-left: 1px solid $main_color; }
		.solidB { border-bottom: 1px solid $main_color; }
		.solidR { border-right: 1px solid $main_color; }

		.dottedT { border-top: 1px dotted $main_color; }
		.dottedL { border-left: 1px dotted $main_color; }
		.dottedB { border-bottom: 1px dotted $main_color; }
		.dottedR { border-right: 1px dotted $main_color; }

		.solidGT { border-top: 1px solid $black20; }
		.solidGL { border-left: 1px solid $black20; }
		.solidGB { border-bottom: 1px solid $black20; }
		.solidGR { border-right: 1px solid $black20; }

		.dottedGT { border-top: 1px dotted $black20; }
		.dottedGL { border-left: 1px dotted $black20; }
		.dottedGB { border-bottom: 1px dotted $black20; }
		.dottedGR { border-right: 1px dotted $black20; }

		.left {float: left;}
		.right {float: right;}


	// ----------------------------------------------------------
	// ul,ol
	// ----------------------------------------------------------
	ul {

		// disc
		// -----------------------------------------------------
		&.disc {
			line-height: 140%;
			>li {
				margin-bottom: 5px;
				text-indent: -1em;
				margin-left: 1em;
				&:before {
					content: "・";
				}
			}
		}

		// notice
		// -----------------------------------------------------
		&.notice {
			line-height: 140%;
			>li {
				margin-bottom: 5px;
				text-indent: -0.5em;
				margin-left: 0.5em;
				padding-left: 0.5em;
				&:before {
					content: "※";
					display: inline-block;
					width: 0.5em;
				}
			}
		}
	}

	ol {

		// Number
		// -----------------------------------------------------
		line-height: 140%;
		list-style-type: decimal;
		>li {
			margin-left: 1.7em;
			margin-bottom: 5px;
		}

		// (i)
		// -----------------------------------------------------
		&.bracket {
			list-style-type: none;
			>li {
				text-indent: -2.5em;
				margin-left: 2em;
			}
		    @for $i from 0 through 20 {
		        >li:nth-child(#{$i + 1}) {
		        	&:before {
		        		content: "（#{$i + 1}）";
		        	}
		        }
		    }
		}
	}

	// ----------------------------------------------------------
	// Table
	// ----------------------------------------------------------
		table {
			border-collapse: collapse;
			border-spacing: 0;
			th,td {
				padding: 15px;
				&.bgGray { background: $black0 !important; color: $text_color; }
				&.col1 { width:$col_1; }
				&.col2 { width:$col_2; }
				&.col3 { width:$col_3; }
				&.col4 { width:$col_4; }
				&.col5 { width:$col_5; }
				&.col6 { width:$col_6; }
				&.col7 { width:$col_7; }
				&.col8 { width:$col_8; }
				&.col9 { width:$col_9; }
				&.col10 { width:$col_10; }
				&.col11 { width:$col_11; }
				&.col12 { width:$col_12; }
				&.colFifth { width:$col_per5; }// 1/5
			}

			&.horizon {
				width: 100%;
				th,td { border-bottom: 1px solid $black20; }
				th { font-weight: bold; }
				thead {
					th,td { border-bottom: 2px solid $black20; }
				}
			}

			&.vertical {
				width: 100%;
				th { border-right: 2px solid $black20; font-weight: bold; }
				td { border-right: 1px solid $black20;
					&:last-child { border-right: none; }
				}
			}

			&.around {
				width: 100%;
				th { border: 1px solid $black20; font-weight: bold; }
				td { border: 1px solid $black20; }
				thead {
					th,td { border-bottom: 2px solid $black20; }
				}
			}

			&.stripe {
				width: 100%;
				tbody {
					tr:nth-child(odd) {
						th,td { background: $black0; }
					}
				}
			}

			&.cursor {
				width: 100%;
				tbody {
					tr {
						&:hover {
							td { background: $black10; }
							&:nth-child(odd) {
								td { background: $black10 !important; }
							}
						}
					}
				}
			}
			&.cellBreak {
				width: 100%;
				th { background:  $black0; color: $text_color; }
			}
		}


	// ----------------------------------------------------------
	// 	Box
	// ----------------------------------------------------------

		// Background
		// -----------------------------------------------------
		.box {
			background: $black0;
			padding: 20px;
		}

		// Border
		// -----------------------------------------------------
		.boxSolid {
			border: 1px solid $black20;
			padding: 20px;
		}

		// Dotted
		// -----------------------------------------------------
		.boxDotted {
			border: 1px dotted $black20;
			padding: 20px;
		}

		// Dashed
		// -----------------------------------------------------
		.boxDashed {
			border: 1px dashed $black20;
			padding: 20px;
		}


	// ----------------------------------------------------------
	// Column
	// ----------------------------------------------------------


		// Defalt
		// -----------------------------------------------------
		.col {
			display:block;

			&:after {
				content: "";
				display: block;
				clear: both;
				height: 0;
				visibility: hidden;
				overflow: hidden;
				font-size: 0.1em;
				line-height: 0;
			}
			>div,>p {
				//overflow: hidden;
			}

			margin: 0 (-1)*($col_margin/2)+px 0 (-1)*($col_margin/2)+px;
			>.col1 { width:$col_1; }
			>.col2 { width:$col_2; }
			>.col3 { width:$col_3; }
			>.col4 { width:$col_4; }
			>.col5 { width:$col_5; }
			>.col6 { width:$col_6; }
			>.col7 { width:$col_7; }
			>.col8 { width:$col_8; }
			>.col9 { width:$col_9; }
			>.col10 { width:$col_10; }
			>.col11 { width:$col_11; }
			>.col12 { width:$col_12; }
			>.colFifth { width:$col_per5; }// 1/5

			>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
				padding-left: ($col_margin/2)+px;
				padding-right: ($col_margin/2)+px;
				margin-bottom: $col_margin_bottom + px;
				float:left;
			}


		// Margin Setting
		// -----------------------------------------------------
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colMg#{$num * 1} {
					margin: 0 (-1)*($num/2)+px 0 (-1)*($num/2)+px;
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						padding-left: ($num/2)+px;
						padding-right: ($num/2)+px;
						margin-bottom: $col_margin_bottom + px;
					}
				}
			}
			@for $i from 0 through 10 {
				$num: $i*10;
				&.colMb#{$num * 1} {
					>.col1,>.col2,>.col3,>.col4,>.col5,>.col6,>.col7,>.col8,>.col9,>.col10,>.col11,>.col12,>.colFifth {
						margin-bottom: $num + px;
					}
				}
			}


		// Table Layout
		// -----------------------------------------------------

			&.table {
				display:table;
				width: 100%;
				table-layout: fixed;
				margin: 0 0 $col_margin_bottom + px;
			}
			.tableCell {
				float: inherit !important;
				display: table-cell;
				vertical-align: middle;
			}

			>.left,>.right {
			//	margin: 0 ($col_margin/2)+px 0 ($col_margin/2)+px;
			}
			>.left {float: left;}
			>.right {float: right;}

		}

	// ----------------------------------------------------------
	// Table Layout
	// ----------------------------------------------------------

		// Defalt
		// -----------------------------------------------------
		.table {
			display:table;
			width: 100%;
			table-layout: fixed;

			>* {
				display: table-cell;
				vertical-align: middle;
			}
			>.tableRow {
				display: table-row;
				>* {
					display: table-cell;
					vertical-align: middle;
				}
			}

			>.cell1, >.tableRow >.cell1 { width:$col_1; }
			>.cell2, >.tableRow >.cell2 { width:$col_2; }
			>.cell3, >.tableRow >.cell3 { width:$col_3; }
			>.cell4, >.tableRow >.cell4 { width:$col_4; }
			>.cell5, >.tableRow >.cell5 { width:$col_5; }
			>.cell6, >.tableRow >.cell6 { width:$col_6; }
			>.cell7, >.tableRow >.cell7 { width:$col_7; }
			>.cell8, >.tableRow >.cell8 { width:$col_8; }
			>.cell9, >.tableRow >.cell9 { width:$col_9; }
			>.cell10, >.tableRow >.cell10 { width:$col_10; }
			>.cell11, >.tableRow >.cell11 { width:$col_11; }
			>.cell12, >.tableRow >.cell12 { width:$col_12; }
			>.cellFifth, >.tableRow >.cellFifth { width:$col_per5; }// 1/5
		}

	// ----------------------------------------------------------
	// Form
	// ----------------------------------------------------------
		.formReset{
			input,
			select,
			button,
			textarea,
			label,
			select{
				outline: 0;
				appearance: none;
				border-radius: 0;
				background: none;
				-webkit-tap-highlight-color:rgba(0,0,0,0);
				font-family: $base_font;
				line-height: $input_height + px;
				font-size: $base_font_size + px;
			    border: 1px solid $black20;
			    letter-spacing: 0.05em;
			}
			label{
				display: inline-block;
				cursor:pointer;
			}

			// Input
			// -----------------------------------------------------
			input{
				&:not([type="radio"]):not([type="checkbox"]):not([type="hidden"]):not([type="range"]):not([type="color"]):not([type="file"]):not([type="submit"]):not([type="image"]):not([type="reset"]):not([type="button"]){
					height: $input_height + px;
					width: 100%;
					padding: 0 5px;
					border: 1px solid $black20;
					&:focus{
						border: 1px solid $input_color;
					}
				}
			}


			// Radio / Checkbox
			// -----------------------------------------------------

			input[type=radio],
			input[type=checkbox] {
			    display: none;
			    margin: 0;
				& + label{
				    position: relative;
				    display: inline-block;
				    margin-right: 10px;
				    padding: 0 0 0 ($inputIcon_size+6)+px;
				    font-size: 14px;
				    cursor: pointer;
				    vertical-align: middle;
				    line-height: $inputIcon_size + px;
				    border: none;
				    &:before{
				        content: "";
				        box-sizing: border-box;
				        position: absolute;
				        top: 50%;
				        left: 0;
				        transform: translateY(-50%);
				        box-sizing: border-box;
				        display: block;
				        width: $inputIcon_size + px;
				        height: $inputIcon_size + px;
				        background: #FFF;
				    }
				}
				&:checked{
					& + label{
						&:after{
					        content: "";
					        box-sizing: border-box;
					        position: absolute;
					        top: 50%;
					        box-sizing: border-box;
					        display: block;
						}
					}
				}
			}
			input[type=radio]{
				& + label{
					&:before{
			        	border: 1px solid $black20;
			        	border-radius: 50%;
					}
				}
				&:checked{
					& + label{
						&:after{
					        left: ($inputIcon_size - $inputIconRadio_size)/2 + px;
					        width: $inputIconRadio_size + px;
					        height: $inputIconRadio_size + px;
				        	transform: translateY(-50%);
					        background: $input_color;
					        border-radius: 50%;
						}
					}
				}
			}
			input[type=checkbox]{
				& + label{
					&:before{
			        	border: 1px solid $black20;
					}
				}
				&:checked{
					& + label{
						&:after{
					        left: ($inputIcon_size - $inputIconCheck_size)/2 + px;
					        width: $inputIconCheck_size + px;
					        height: $inputIconCheck_size/2 + px;
					        margin-top: -($inputIconCheck_size/2)+2 + px;
					        border-left: 3px solid $input_color;
					        border-bottom: 3px solid $input_color;
					        transform: rotate(-45deg);
						}
					}
				}
			}

			// Select
			// -----------------------------------------------------

			.select{
				$selectArrow_size: 3;
				width: 100%;
				border: none;
				position: relative;
				@include arrow($color:#000000, $size:$selectArrow_size, $layout:left);
				@include arrow($color:#000000, $size:$selectArrow_size, $layout:right);
				&:before,
				&:after{
					transform-origin: 0% 50%;
					position: absolute;
					top: 50%;
					right: 10px;
					margin: 0;
					display: none\9;
					margin-top: -($selectArrow_size*1.4/2)+px;
				}
				&:before{
					transform: rotate(-45deg) translateY(-50%);
				}
				&:after{
					transform: rotate(135deg) translateY(-50%);
				}
			}
			select{
				width: 100%;
				height: $input_height + px;
				position: relative;
				padding: 0 24px 0 5px;
				&:focus{
					border: 1px solid $input_color;
				}
	            // macのfirefoxでfocus中に点線が表示されるのを防ぐ
	            &:-moz-focusring {
	            	color:transparent;
	                text-shadow:0 0 0 #000;
	            }
	            &:-moz-focusring * {
	                color:#000;
	                text-shadow:none;
	            }
	            // IE10 矢印ボタンを非表示
				&::-ms-expand {
					display: none;
				}
			}

			// Textarea
			// -----------------------------------------------------

			textarea{
				width: 100%;
				height: 150px;
				resize: none;
				padding: 5px;
				&:focus{
					border: 1px solid $input_color;
				}
			}

			// Submit / Reset / Button
			// -----------------------------------------------------

			input[type="submit"],
			button[type="submit"],
			input[type="reset"],
			button[type="reset"],
			input[type="button"],
			button[type="button"]{
				cursor: pointer;
				height: 30px;
			}
		}


}//.fw
